import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

@NgModule({
    imports:[ CommonModule],
    declarations:[],
    providers:[]
})
export class SharedModule {

}